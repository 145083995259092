import { Box, Container, Flex, Grid, Heading, Input, Text } from "theme-ui"
import { ExternalLink, InternalLink } from "./link"
import React, { useEffect } from "react"
import { globalDocument, globalWindow } from "../utils/dom"

import BlockContainer from "./block-container"
import MenuItem from "./menu-item"

const FooterMenuBlock = ({ caption, children, text, title }) => {
  return (
    <Box>
      <Heading as="h3" variant="h3">
        {title}
      </Heading>
      <Box mt={3}>
        {caption && text ? (
          <>
            <Text variant="caption2">{caption}</Text>
            <Text mt={2}>{text}</Text>
          </>
        ) : (
          children
        )}
      </Box>
    </Box>
  )
}

const ContentfulFooterBlock = ({
  address,
  backgroundColor,
  copyright,
  socialLinksTitle,
  blogTitle,
  newsletterJoinTitle,
  facebookLink,
  gif,
  gifDescription,
  instagramLink,
  linkedInLink,
}) => {
  useEffect(() => {
    const footerRef = globalDocument.querySelector("#footer")
    const headerBackgroundRef = globalDocument.querySelector(
      "#header-background-layer",
    )
    const scrollRef = globalDocument.querySelector("html")

    const onScroll = () => {
      const offset =
        footerRef.getBoundingClientRect().top - globalWindow.innerHeight * 0.5
      if (offset <= 0) {
        const opacity = (-offset / footerRef.clientHeight) * 2
        // @ts-ignore
        headerBackgroundRef.style.backgroundColor = `rgba(255, 255, 255, ${
          1 - opacity
        })`
        // @ts-ignore
        scrollRef.style.backgroundColor = `rgba(${backgroundColor}, ${opacity})`
      } else {
        // @ts-ignore
        headerBackgroundRef.style.backgroundColor = `rgba(255, 255, 255, 1)`
        // @ts-ignore
        scrollRef.style.backgroundColor = `rgba(${backgroundColor}, 0)`
      }
    }

    if (footerRef && headerBackgroundRef && scrollRef) {
      scrollRef.addEventListener("scroll", onScroll)
    }

    hbspt.forms.create({
      portalId: "7144281",
      formId: "d58c668a-68e1-4729-a56e-91023ef0ca8c",
      target: `#hubspotForm-newsletter`,
    })

    return () => {
      scrollRef.removeEventListener("scroll", onScroll)
    }
  }, [])

  return (
    <Box id="footer">
      <BlockContainer>
        <Box>
          <Grid columns={[1, 1, 2, 3]} gap="64px" mt={5}>
            <FooterMenuBlock
              children={
                <>
                  <ExternalLink href={instagramLink || ""}>
                    <MenuItem
                      identifier="menu-footer-instagram"
                      label="Instagram"
                    />
                  </ExternalLink>
                  <ExternalLink href={facebookLink || ""}>
                    <MenuItem
                      identifier="menu-footer-facebook"
                      label="Facebook"
                    />
                  </ExternalLink>
                  <ExternalLink href={linkedInLink || ""}>
                    <MenuItem
                      identifier="menu-footer-linkedin"
                      label="LinkedIn"
                    />
                  </ExternalLink>
                </>
              }
              title={socialLinksTitle || "Stalk us"}
            />
            <InternalLink href="/blog">
              <FooterMenuBlock title={blogTitle} />
            </InternalLink>
            <FooterMenuBlock
              children={
                <Box sx={{ mt: -3 }}>
                  <div id="hubspotForm-newsletter"></div>
                </Box>
              }
              title={newsletterJoinTitle}
            />
          </Grid>
          <Flex sx={{ alignItems: "center", flexDirection: "column", mt: 6 }}>
            <Text variant="caption1" sx={{ textAlign: "center" }}>
              {address}
            </Text>
            <Text variant="copyright" sx={{ mt: 4, textAlign: "center" }}>
              {copyright}
            </Text>
            {(gif || gifDescription) && (
              <Flex
                sx={{ alignItems: "center", flexDirection: "column", mt: 4 }}
              >
                {gifDescription && (
                  <Text variant="copyright" sx={{ mb: 3, textAlign: "center" }}>
                    {gifDescription}
                  </Text>
                )}
                {gif && (
                  <a
                    href={gif.file?.url}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img
                      alt="gif"
                      src={gif.resize?.src}
                      style={{ height: "35px", width: "35px" }}
                    />
                  </a>
                )}
              </Flex>
            )}
          </Flex>
          <Flex
            sx={{
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              flexDirection: "row",
              mt: 3,
            }}
          >
            <img
              alt="B Corp"
              src="/b-corp.png"
              style={{
                width: "75px",
                marginRight: 32,
              }}
            />
            <img
              alt=""
              src="/wbe.svg"
              style={{
                width: "100px",
              }}
            />
          </Flex>
        </Box>
      </BlockContainer>
    </Box>
  )
}

export default ContentfulFooterBlock
